import { Link, useParams } from 'react-router-dom'
import React, { useEffect, useState }  from 'react'
import { getArticleById } from '../../Shopify/Queries/GetBlogById'
import { toast } from 'react-toastify'
import moment from 'moment'

export default function BlogPage() {

  const { id } = useParams()
  const [data, setData] = useState(null)

  useEffect( () => {
    async function getData() {
      let {data, errors} = await getArticleById(`gid://shopify/Article/${id}`)
      if(errors){
        toast.error("Error getting blog")
      } else {
        setData(data)
      }
    }
    getData()
  }, [id])

  return (
    <div id='blog-page'>
      {console.log(data)}
      <div style={{width: 1200, maxWidth: '100%', margin: '0 auto', color: 'white', fontFamily: 'Futura-PT, sans-serif'}}>

        <p >{moment(data?.article.publishedAt).format("MMMM DD, YYYY")}</p>

        <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', flexWrap:'wrap'}}>
          <p style={{color: '#FFB5FF', fontFamily: 'BigSans, sans-serif', fontSize: 38, margin: 0}}>{data?.article.title}</p>

          <div style={{backgroundColor:'#FFB5FF', padding: '10px 20px'}}>
            <p style={{fontFamily: 'BigSans, sans-serif', color:'#FB4622', margin: '3px 0 0 0'}}>#{data?.article.blog.title}</p>
          </div>
        </div>
       
        <img src={data?.article.image.url} style={{width:'100%', objectFit:'cover', objectPosition:'center', height: 600, margin: '20px 0'}} />
        <div style={{fontSize: 20}} dangerouslySetInnerHTML={{ __html: data?.article.contentHtml }} />

        <Link to={'/blogs'} style={{textTransform:'uppercase', color: '#FFB5FF', textDecoration: 'underline'}}>Back to Blogs</Link>
      </div>
    </div>
  )
}
