import React, { useState } from 'react'
import './Modal.css'
import logo from '../../../Assets/logo-new.webp'
import { toast } from 'react-toastify';
import axios from 'axios'

export default function DiscountModal({ setDiscountOpen }) {

  const [ number, setNumber] = useState("")
  const [ loading, setLoading] = useState(false)

const handleSubmit = () => {
  if(!number){
    toast.error("Please add number")
  } else {
    setLoading(true)
    axios.post(`${process.env.REACT_APP_API_URL}/thiago/discount`, {number})
    .then(() => {
      toast.success("Subscribed successfully")
      localStorage.setItem('receivedDiscount', 'true');
      setNumber("")
      setDiscountOpen(false)
    })
    .catch(() => {
      toast.error("Something went wrong")
    })
    .finally(() => {
      setLoading(false)
    })

  }
}

  return (
    <div className='modal'>
        <div className='discount-modal-container'>

            <img src={logo} alt='Thiago Tequila logo' style={{width: '100%', margin: '2rem auto'}}/>

            <p id='are-you' style={{width: 700}}>Kickstart the party with 15% off</p>

            <form  style={{margin: '20px auto'}} id='footer-form'  onSubmit={(e) =>{ e.preventDefault(); handleSubmit()}}>
                  <input id='modal-input' type='text' placeholder='Your Mobile Number' value={number} onChange={(e)=>setNumber(e.target.value)} />
                  <button 
                    id='footer-btn' 
                    type='submit'
                    disabled={loading}
                    style={{backgroundColor:'black', color:'white', minWidth: 150}}
                  >
                      Sign Up
                  </button>
              </form>


            <p id='you-must' 
              style={{
                  color: '#FF3F00',
                  fontFamily: 'GreenGroveBold, sans-serif', 
                  textTransform: 'uppercase', 
                  fontWeight: 900, 
                  textAlign:'center',
                  fontSize: 42
                }}>
              Discover a new world of flavour with Thiago Tequila
            </p>

                <button                     
                  id='footer-btn' 
                  onClick={() => setDiscountOpen(false)}
                  style={{margin: '0 auto'}}
                >
                  Close
                </button>
          

            <p style={{fontFamily: 'BigSans, sans-serif', textAlign:'center', marginTop:30}}>Copyright © {new Date().getFullYear()} Thiago Tequila Co. You must be 18 or over to consume alcohol. Please drink responsibly.</p>

        </div>
    </div>
  )
}
