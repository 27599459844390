import React from 'react';
import moment from 'moment'
import { Link } from 'react-router-dom'

export default function BlogCard({ data, id , category}) {
  console.log(data)
  return (
    <div className="blog-card">
     
      <div style={{backgroundImage:`url("${data.image.url}")`}} className="blog-card-image">
        <div className='blog-category'>
          <p style={{margin: 0}}>#{category}</p>
        </div>
      </div>

      <p style={{color:'white', fontFamily: 'Futura-PT, sans-serif', margin: '10px 0'}}>
        {moment(data.publishedAt).format("MMM DD, YYYY")}
      </p>
      <p style={{fontSize: 26, margin: 0}}>{data.title}</p>

      <Link to={id} className='blog-link'>Read Full Story</Link>
    </div>
  );
}