import React, { createContext, useState, useEffect, useContext } from 'react';
import { getAllBlogs } from '../Shopify/Queries/GetAllBlogs';

// Create the context
const BlogContext = createContext();

// Create the provider component
export const BlogGlobalContext = ({ children }) => {
  const [blogs, setBlogs] = useState([]);
  const [loadingBlogs, setLoading] = useState(true);
  const [blogError, setError] = useState(null);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const { data , errors} = await getAllBlogs();
        if(errors){
            setError(errors)
        } else {
            setBlogs(data); 
        }

      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  return (
    <BlogContext.Provider value={{ blogs, loadingBlogs, blogError }}>
      {children}
    </BlogContext.Provider>
  );
};

// Custom hook for using the BlogContext
export const useBlogContext = () => {
  return useContext(BlogContext);
};
