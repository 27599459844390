import { client } from '../shopify';

export const GET_ARTICLE_QUERY = `
  query getArticle($id: ID!) {
    article(id: $id) {
      title
      blog {
        title
      }
      contentHtml
      publishedAt
      image {
        url
      }
    }
  }
`;

export async function getArticleById(id) {

  const response = await fetch(client.getStorefrontApiUrl(), {
    method: 'POST',
    headers: client.getPublicTokenHeaders({ buyerIp: '...' }),
    body: JSON.stringify({
      query: GET_ARTICLE_QUERY,
      variables: { id },
    }),
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  const data = await response.json();
  return data;
}
