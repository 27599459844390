import { client } from '../shopify';

export const GET_BLOGS_QUERY = `
     query {
    blogs(first: 5) {
        nodes {
          id
          title
          handle
          articles(first: 18) {
              nodes {
                id
                image {
                    url
                }
                title
                handle
                publishedAt
                content
                contentHtml
            }
          }
        }
      }
    }
`;

export async function getAllBlogs() {
  const response = await fetch(client.getStorefrontApiUrl(), {
    body: JSON.stringify({
      query: GET_BLOGS_QUERY,
    }),
    headers: client.getPublicTokenHeaders({ buyerIp: '...' }),
    method: 'POST',
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  const data = await response.json();
  return data;
}
