import React, { useState} from 'react'
import instagram from '../../Assets/icons/insta.webp'
import tiktok from '../../Assets/icons/tiktok.webp'
import fb from '../../Assets/icons/fb.webp'
import './Footer.css'
import logo from '../../Assets/blue-logo.webp'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify'
import shopify from '../../Assets/shopify.png'

export default function Footer() {


    const [ email, setEmail] = useState("")
    const [ loading, setLoading] = useState(false)

  const handleSubmit = () => {
    if(!email){
      toast.error("Please add email")
    } else {
      setLoading(true)
      axios.post(`${process.env.REACT_APP_API_URL}/thiago/newsletter`, {email})
      .then(() => {
        toast.success("Subscribed successfully")
        setEmail("")
      })
      .catch(() => {
        toast.error("Something went wrong")
      })
      .finally(() => {
        setLoading(false)
      })

    }
  }

  return (
    <>
    <footer>

      <div id='footer-container'>
        <img id='footer-logo' src={logo}/>

       
            <div >
              <p id='join-the-party'>Join the party</p>

              <form id='footer-form' onSubmit={(e) =>{ e.preventDefault(); handleSubmit()}}>
                  <input id='footer-input' type='text' placeholder='Your Email' value={email} onChange={(e)=>setEmail(e.target.value)} />
                  <button id='footer-btn' type='submit' disabled={loading}>Sign Up</button>
              </form>
            </div>
       
      </div>

   

    <div id='socials-container'>
        <a href='https://www.instagram.com/thiagotequila' target='_blank'><img className='social-icon' src={instagram} alt='instagram' /></a>
        <a href='https://www.tiktok.com/@thiago_tequila' target='_blank'><img className='social-icon' src={tiktok} alt='tiktok' /></a>
        <a href='https://www.facebook.com/ThiagoTequilaLtd/' target='_blank'><img className='social-icon' src={fb} alt='facebook' /></a>
        <div id='footer-links'>
          <Link to={'/policies/privacy-policy'} className='footer-link'>PRIVACY POLICY</Link>
          <Link to={'/policies/terms-of-service'} className='footer-link'>TERMS & CONDITIONS</Link>
          <Link to={'customer-service'} className='footer-link'>CUSTOMER SERVICE</Link>

        </div>
        <p style={{fontSize: '0.6rem'}}>COPYRIGHT © {new Date().getFullYear()} THIAGO TEQUILA CO. YOU MUST BE 18 OR OLDER TO CONSUME ALCOHOL. PLEASE DRINK RESPONSIBLY.</p>

    </div>

    


   

    <a href='https://www.frantech.org.uk' style={{color: '#FB4622',marginBottom: 20, fontSize: '0.5rem', position: 'absolute', bottom: 0, left: 0}}>WEBSITE BY FRANTECH</a>
</footer>
    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0 0 1rem 0', position: 'relative', top: -10}}>
      <p style={{fontFamily: 'futura-pt, sans-serif', color: '#313131'}}>Powered by</p>
      <img style={{width: 150, maxWidth: '60vw', marginLeft: 10}} src={shopify}/>
    </div>
</>
  )
}
